<template>
    <div class="editOver-container">
        <CSDialog
                :dialogVisible="addBuildingVisible"
                :dialogTitle="dialogT"
                dialogWidth="620px"
                @onClose="addBuildingVisible = false"
                @onConfirm="saveFloorInfo"
                :dialog-confirm-btn-text="dialogT == '添加楼栋'?'保存':'修改'"
                @click="allRoomBtn"
        >
            <div
                    slot="dialog-content"
                    class="add-building-content"
            >
                <div class="building-item">
                    <label class="building-item-name">楼名</label>
                    <div class="building-item-content">
                        <input
                                v-model="addBuildingParams.name"
                                type="text"
                                placeholder="限10个字" maxlength="10"
                                class="cs-input"
                                :disabled="isDisable"
                        />
                    </div>
                </div>
                <div class="building-item">
                    <label class="building-item-name">楼栋编号</label>
                    <div class="building-item-content">
                        <input
                                v-model="addBuildingParams.buildingNumber"
                                type="number"
                                placeholder="请输入" maxlength="10"
                                class="cs-input"
                        />
                    </div>
                </div>
                <div class="building-item">
                    <label class="building-item-name" style="line-height:1.8">楼层</label>
                    <div class="building-item-content" style="vertical-align: top">
                        <p>
                            <span style="width: 145px;display: inline-block;text-align: right;">地上层数</span>
                            <CSSelect height="40px" i-width="36px">
<!--                                :disabled="isDisable"-->
                                <select v-model="addBuildingParams.aboveGroundFloor" style="color: #999999"  :disabled="isDisable" @change="changeFloor">
                                    <option value="">请选择</option>
                                    <option v-for="n in 100" :value="n" :key="`${n}xx`">{{n}}</option>
                                </select>
                            </CSSelect>
                        </p>
                        <p>
                            <span style="width: 145px;display: inline-block;text-align: right;">地下层数</span>
                            <CSSelect height="40px" i-width="36px">
                                <select v-model="addBuildingParams.undergroundFloor" style="color: #999999"  :disabled="isDisable">
                                    <option value="">请选择</option>
                                    <!--                                    <option :value="0">0</option>-->
                                    <option v-for="n in 10" :key="n" :value="n">{{n}}</option>
                                </select>
                            </CSSelect>
                        </p>
                        <p>
                            <span style="width: 145px;display: inline-block;text-align: right;">连通楼栋楼层</span>

                            <AllRoomSelectOwner
                                    :relevanceBuilding = "!relevanceBuilding?[]:relevanceBuilding.split(',')"
                                    @changeContractRooms="changeContractRooms"
                                    :relevance="relevance?relevance:[]"
                                    :selectedRooms="roomIds"
                                    :isSelectedRooms="isSelectedRooms"
                                    :checkedRoomNames="checkedRoomNames"
                                    :topBuilding="addBuildingParams.aboveGroundFloor?addBuildingParams.aboveGroundFloor:1"
                                    :isDisable="addBuildingParams.aboveGroundFloor != ''&& addBuildingParams.aboveGroundFloor > 1"
                                    style="display: inline-block"
                            ></AllRoomSelectOwner>
                        </p>
                    </div>
                </div>

            </div>
        </CSDialog>
    </div>
</template>
<script>
    import {creatBuildingUrl,editBuildingUrl} from "@/requestUrl";
    import CSDialog from "@/components/common/CSDialog";
    import CSSelect from "@/components/common/CSSelect";
    import TreePanel from "@/components/common/TreePanel";
    import CSTree from "@/components/common/CSTree";
    import AllRoomSelectOwner from "@/components/AddFloorSelect";
    export default {
        props: {callBackListener: String, callBackFunction: String},
        components: {
            CSDialog,
            CSSelect,
            TreePanel,
            CSTree,
            AllRoomSelectOwner
        },
        data() {
            return {
                relevanceBuilding:'',
                relevance:null,
                floorRoomList:'',
                roomIds: [],//选中楼栋
                isSelectedRooms: [],
                checkedRoomNames: [],
                id:'',
                dialogT:'添加楼栋',
                addBuildingParams: {
                    regionCode: this.$vc.getCurrentRegion().communityId,
                    name: "",
                    photos: "",
                    buildingNumber: '',
                    lat: "",
                    lon: "",
                    aboveGroundFloor: '',
                    undergroundFloor: '',
                    relevanceBuildings:[],
                },
                // 添加楼栋框显隐
                addBuildingVisible: false,
            };
        },
        computed:{
            isDisable:function (){
                return this.dialogT == '修改楼栋'
            }
        },
        mounted() {
            this.$vc.on(this.$route.path, "addFloor", "openAddFloorModal", (item) => {
                console.log(item);
                if(item){
                    console.log(11111111111111111);
                    console.log(item.relevance);
                    this.dialogT = '修改楼栋'
                    this.addBuildingParams.name = item.name;
                    this.addBuildingParams.buildingNumber = item.buildingNumber;
                    this.addBuildingParams.aboveGroundFloor = item.aboveGroundFloor;
                    this.addBuildingParams.undergroundFloor = item.undergroundFloor;
                    this.relevance = item.relevance;
                    this.relevanceBuilding = item.relevanceBuilding;
                    console.log('relevanceBuilding');
                    console.log(this.relevanceBuilding );
                    this.id = item.id;

                }else{
                    this.dialogT = '添加楼栋'
                    this.addBuildingParams.name = '';
                    this.addBuildingParams.buildingNumber = '';
                    this.addBuildingParams.aboveGroundFloor = '';
                    this.addBuildingParams.undergroundFloor = '';
                    this.addBuildingParams.relevanceBuildings = [];
                    this.relevanceBuilding = "";
                    this.relevance = null
                }
                this.addBuildingVisible = true;
            });
        },
        methods: {
            changeFloor(){
                this.$vc.emit('changeFloor',[])
            },
            /**
             * 修改签约合同的房间
             * @param {Object} roomInfo 选中的所有房间信息
             *  */
            changeContractRooms(roomInfo) {

               let selectArr = [];
               let floorList = [];
               let buildingIdList = [];
               for(var i = 0;i<roomInfo.length;i++){
                   let str = roomInfo[i].split('-')[0];
                   let strd = roomInfo[i].split('-')[1];
                   let buildId = roomInfo[i].split('-')[2];
                   if(!selectArr.includes(str)){
                       selectArr.push(str);
                       buildingIdList.push(buildId);
                   }
                   floorList.push({
                       building:str,
                       value:strd
                   })
               }
               console.log(floorList);
                this.addBuildingParams.relevanceBuildings = selectArr.map((item,index) => {
                   return {
                       relevanceCode:buildingIdList[index],
                       relevanceID:item,
                       relevanceFloor:floorList.filter(res =>  res.building == item).map(gg => +gg.value)
                   }
               })
                //选中的数组
                console.log('选中的数组');
               console.log(this.addBuildingParams.relevanceBuildings);
            },
            //点击面板，控制显示隐藏
            allRoomBtn() {
                this.$vc.emit("allRoomSelectContract", "selcet", false);
                // this.$vc.emit("allRoomSelectOwner", "selcet", {});
            },
            saveFloorInfo: function () {
                if(this.dialogT == '添加楼栋'){
                    this.$fly
                        .post(creatBuildingUrl, this.addBuildingParams)
                        .then(res => {
                            if (res?.code !== 0) {
                                this.$vc.message(res?.msg || '添加楼栋失败');
                                return;
                            }
                            this.$vc.toast('添加成功');
                            this.addBuildingVisible = false;
                            this.clearAddFloorInfo();
                            this.$vc.emit(this.$route.path, "listFloor", "listFloorData", {});
                        });
                }else{
                    console.log('修改参数');
                    console.log(this.addBuildingParams.relevanceBuildings);
                    let addBuildingParams = {
                        "id":this.id,//楼栋ID，必填
                        "regionCode":this.addBuildingParams.regionCode,
                        "name":this.addBuildingParams.name,
                        "lat":'', //纬度，选填
                        "lon":'', //经度，选填
                        "photos":"", //图片url，选填
                        "buildingNumber": this.addBuildingParams.buildingNumber,//楼栋编号
                        "relevanceBuildings":this.addBuildingParams.relevanceBuildings
                    }
                    this.$fly
                        .post(editBuildingUrl, addBuildingParams)
                        .then(res => {
                            if (res?.code !== 0) {
                                this.$vc.message(res?.msg || '修改楼栋失败');
                                return;
                            }
                            this.$vc.toast('修改成功');
                            this.addBuildingVisible = false;
                            this.clearAddFloorInfo();
                            this.$vc.emit(this.$route.path, "listFloor", "listFloorData", {});
                        });
                }

            },
            clearAddFloorInfo: function () {
                this.addBuildingParams = {
                    regionCode: this.$vc.getCurrentRegion().communityId,
                    name: "",
                    photos: "",
                    lat: "",
                    lon: "",
                    aboveGroundFloor: '',
                    undergroundFloor: '',
                };
            },
        },
    };
</script>

<style lang="stylus" scoped>
    .building-item-name {
        width 96px !important
        text-align right
    }

    .building-item-name[type = 'number'] {
        -moz-appearance textfield
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        display none !important
    }

    .add-building-content
        padding 27px 30px

        .building-item
            margin-bottom 20px
            font-size 0

            &-name
                font-size 24px
                margin-right 40px
                width 50px
                display inline-block
                vertical-align middle
                line-height 1
                margin-bottom 0

            &-content
                font-size 24px
                display inline-block
                vertical-align middle

                .cs-input
                    width 400px
                    height 40px
                    border-radius 4px
                    border 1px solid #979797
                    text-indent 5px

                select
                    text-indent 2px

                & > p
                    margin-bottom 0

                    span
                        display inline-block
                        vertical-align middle
                        margin-right 10px

                    &:not(:last-of-type)
                        margin-bottom 14px

        .tips
            font-size 20px
            color #999

</style>
